import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { ModalDownload } from '@components/Modals';
import './List.en.scss';
import scrollTo from 'gatsby-plugin-smoothscroll';


const ListEn = (data) => {
  const pieces = data.data.nodes;
  const street = pieces.filter(el => el.fieldsPieces.type === 'streets');
  const studio = pieces.filter(el => el.fieldsPieces.type === 'studio');
  const nft = pieces.filter(el => el.fieldsPieces.type === 'nft');

  const canvas = [];
  const collage = [];
  const escultura = [];
  const strappo = [];
  const abstracto = [];

  studio.map((el)=> {
    switch (el.fieldsPieces.category) {
      case 'Abstracto':
        abstracto.push(el);
        break;
      case 'Canvas':
        canvas.push(el);
        break;
      case 'Collage':
        collage.push(el);
        break;
      case 'Escultura':
        escultura.push(el);
        break;
      case 'Strappo':
        strappo.push(el);
        break;
      default:
        break;
    }})
 
  const modalDownloadRef = useRef();
  const [dataModal, setDataModal] = useState();

  const [openTab, setopenTab] = useState(typeof window !== "undefined" ?  window.localStorage.getItem('_active_tab_list') ?? 1 : false);
  const setLocalStorage = value => {
    setopenTab(value)
    try {
      window.localStorage.setItem("_active_tab_list", value)
    } catch (error) {
      console.error(error);
    }
  };

  const select = (target) => {
    scrollTo(target);
  };

  return (
    <>
      <section id="pieces-list-en">
        <h1 className="text-center">The pieces</h1>
        <div className="tabs-container">
          <div className="buttons">
            <button className={`btn btn--stroke tab-button ${ openTab ==  1 ? 'active' :''}`} tab-button="#tab-1" onClick={()=> setLocalStorage(1)}>Streets</button>
            <button className={`btn btn--stroke tab-button ${ openTab ==  2 ? 'active' :''}`} tab-button="#tab-2" onClick={()=> setLocalStorage(2)}>Studio</button>
            <button className={`btn btn--stroke tab-button ${ openTab ==  3 ? 'active' :''}`} tab-button="#tab-3" onClick={()=> setLocalStorage(3)}>NFTS</button>
          </div>
          {openTab == 2 ? 
            <React.Fragment>
                <div className='buttons new-sections'>
                  <button className="tab-button" onClick={() => scrollTo('#Abstract')}>
                    Abstract
                  </button>
                  <button className="tab-button" onClick={() => scrollTo('#Canvas')}>
                    Canvas
                  </button>
                  <button className="tab-button" onClick={() => scrollTo('#Collage')}>
                    Collage
                  </button>
                  <button className="tab-button" onClick={() => scrollTo('#Sculpture')}>
                    Sculpture
                  </button>
                  <button className="tab-button" onClick={() => scrollTo('#Strappo')}>
                    Strappo
                  </button>
                </div>

                <div className="buttons new-sections sections">
                  <select className="form-select" defaultValue="" onChange={(ev)=> select(ev.target.value)} required>
                    <option value="#Abstracto"> Abstract </option>
                    <option value="#Canvas">    Canvas    </option>
                    <option value="#Collage">   Collage   </option>
                    <option value="#Escultura"> Sculpture </option>
                    <option value="#Strappo">   Strappo   </option>
                  </select>
                </div>
            </React.Fragment> 
          : <></>}

          {openTab == 1 ? <>          
            <div className='tabs active'>
              <div className="masonry-pieces">
                { street.map((el)=> {
                    return (
                      <Link
                        to={`/en/pieces/${el.slug}`}
                        className="art"
                        aria-hidden="true"
                      >
                        <picture>
                          <img src={el.featuredImage.node.sourceUrl} alt="Pieza 1" />
                        </picture>
                        <OverlayDetail />
                      </Link>
                    )
                  })}
              </div>
            </div>
          </> : <></> }
          {openTab == 2 ? <>
            <div className='tabs active'>
              <div className="masonry-pieces">
                <h1 className="text-center" id="Abstract">Abstract</h1>
                { abstracto.map((el)=> (
                  <Link
                    to={`/en/pieces/${el.slug}`}
                    className="art"
                    aria-hidden="true"
                    key={el.id}
                  >
                    <picture>
                      <img src={el.featuredImage.node.sourceUrl} alt="Pieza 1" />
                    </picture>
                    <OverlayDetail />
                  </Link>  
                ))}

                <h1 className="text-center" id="Canvas">Canvas</h1>
                { canvas.map((el)=> (
                  <Link
                    to={`/en/pieces/${el.slug}`}
                    className="art"
                    aria-hidden="true"
                    key={el.id}
                  >
                    <picture>
                      <img src={el.featuredImage.node.sourceUrl} alt="Pieza 1" />
                    </picture>
                    <OverlayDetail />
                  </Link>  
                ))}

                <h1 className="text-center" id="Collage">Collage</h1>
                { collage.map((el)=> (
                  <Link
                    to={`/en/pieces/${el.slug}`}
                    className="art"
                    aria-hidden="true"
                    key={el.id}
                  >
                    <picture>
                      <img src={el.featuredImage.node.sourceUrl} alt="Pieza 1" />
                    </picture>
                    <OverlayDetail />
                  </Link>  
                ))}

                <h1 className="text-center" id="Sculpture">Sculpture</h1>
                { escultura.map((el)=> (
                  <Link
                    to={`/en/pieces/${el.slug}`}
                    className="art"
                    aria-hidden="true"
                    key={el.id}
                  >
                    <picture>
                      <img src={el.featuredImage.node.sourceUrl} alt="Pieza 1" />
                    </picture>
                    <OverlayDetail />
                  </Link>  
                ))}

                <h1 className="text-center" id="Strappo">Strappo</h1>
                { strappo.map((el)=> (
                  <Link
                    to={`/en/pieces/${el.slug}`}
                    className="art"
                    aria-hidden="true"
                    key={el.id}
                  >
                    <picture>
                      <img src={el.featuredImage.node.sourceUrl} alt="Pieza 1" />
                    </picture>
                    <OverlayDetail />
                  </Link>  
                ))}
              </div>
            </div>
          </> : <></>}
          {openTab == 3 ? <>
            <div className="tabs active">
              <div className="masonry-pieces">
                  {nft.map((el)=> {
                        return (
                      <Link
                        to={`/en/pieces/${el.slug}`}
                        className={`art ${el.fieldsPieces.imagenes.variables.vendido ? 'sold-out' : '' }`}
                        aria-hidden="true"
                        key={el.id}
                      >
                        <picture>
                          <img src={el.featuredImage.node.sourceUrl} alt="Pieza 1" />
                        </picture>
                        <OverlayDetail />
                      </Link>
                    )
                  })}
              </div>
            </div>
          </> : <></>}
        </div>
      </section>
      <ModalDownload modalDownloadRef={modalDownloadRef} data={dataModal} />
    </>
  );
};
const OverlayDetail = () => {
  return (
    <div className="overlay-detail">
      <p>
        View details <i className="icon icon-arrow-right--white"></i>
      </p>
    </div>
  );
};
export default ListEn;