import React from 'react';
import Layout from '../layouts/es.jsx';
import { List } from '@components/pages/pieces';
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/seo";

const PiecesPage = () => {
  const {wpPage, allWpPiece}  = useStaticQuery(graphql`
      query PiecesSpanish {
        allWpPiece(filter: {link: {regex: "/^(?!(en\/|\/en\/))/i"}}) {
          nodes{
            slug
            featuredImage{
              node{
                sourceUrl
                altText
              }
            }
            title
            id
            date
            fieldsPieces{
              place
              date
              tecnica
              type
              medidas
              category
              imagenes {
                variables {
                  video
                  vendido
                  nftLink
                }
                gallery {
                  sourceUrl
                  link
                }
              }
              backgroundMobile{
                sourceUrl
                altText
              }
            }
          }
        }
        wpPage(slug: {eq: "piezas"}) {
          id
          date
          title
          slug
          status
          featuredImage {
            node {
              sourceUrl
            }
          }
          language {
            slug
            name
            locale
            id
            homeUrl
            code
          }
          seo {
            canonical
            metaDesc
            opengraphDescription
            opengraphImage {
              altText
              link
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphSiteName
            opengraphPublisher
            opengraphTitle
            opengraphType
            opengraphUrl
            schema {
              raw
            }
            title
            twitterDescription
            twitterImage {
              altText
              link
            }
            twitterTitle
          }
        }
      }
      `);
  return (
    <>
      <Seo seo={wpPage.seo}
           author={false}
           lang={wpPage.language.locale}
           thumbnail={wpPage.featuredImage ? wpPage.featuredImage.node.sourceUrl : ''}
           pathname="/piezas"/>
      <Layout pageId="piezas">
        <div className="container">
          <List key={allWpPiece.nodes.id} data={allWpPiece} />
        </div>
      </Layout>
    </>
  );
};

export default PiecesPage;
